import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '../../components/form/field';
import ColorPicker from '../../components/form/color';
import Button from '../../components/chrome/button';
import _ from 'lodash';
import AssetPicker from '../assets/picker';
import { imageResize } from '../../helpers/s3';
import { getAssets } from '../../actions/asset';

const Submit = (values, dispatch, props) => {

	/*dispatch(accountLogin({ 
		email: values.email, 
		password: values.password 
	})).then(response => {
				
		if(response){
			props.history.push(props.parentPath);
		}
	});*/
}

const BrandsEdit = props => {

	const dispatch = useDispatch();
	const { handleSubmit } = props;
	const [code, setCode] = useState('');
	const [colors, setColors] = useState({});
	const [assets, setAssets] = useState({});
	
	const asset = useSelector(state => state.asset);
	
	useEffect(() => {
		console.log('useEffect', 'BrandsEdit')
		dispatch(getAssets());
	}, []);
	
	const Colors = ({ types }) => {
		
		console.log(types)
		
		return _.map(types, (type, key) => {
			
			return (
				<ColorPicker
					key={key}
					label={type}
					color={colors[type]}
					onChange={(color) => { 
						let newColors = colors;
						newColors[type] = color;
						setColors(newColors);
					}}
				 />									
			)
		})
	}
	
						
	const Asset = ({ id }) => {
				
		if(asset.files && asset.files[id]){
		
			return (
				<img src={imageResize(asset.files[id].filename, 500)} />
			);
		}
		
		return null;
	}			
	
	return (
		<div>
			<div className="header">
				<h3>
					Edit
				</h3>
			</div>
			
			
			
			
			
			<form onSubmit={handleSubmit}>			
				
				<div className="row">
					<div className="col-12 col-sm-6 col-md-8">
						
						<legend>General</legend>
						
						<Field 
							name="name" 
							type="text" 
							label="Name" 
							component={FormField} 
						/>
						
						<Field 
							name="title" 
							type="text" 
							label="Hub Title" 
							component={FormField} 
						/>
						
						<Field 
							name="domain" 
							type="text"
							label="Domain" 
							prepend="https://"
							append=".haporiwellbeing.com"
							component={FormField} 
						/>
						
						<Field 
							name="code" 
							type="text"
							pattern="\d*"
							label="Access Code"
							selected={code}
							onChangeFunc={(e) => {
								setCode(e.target.value.replace(/\s+/g, '').substr(0, 6));
							}}
							component={FormField} 
						/>
						
						<legend>Branding</legend>
						
						<div className="row">
							<div className="col-12 col-sm-12 col-md-4">
								<label className="label-main">Main Logo</label>
								
								{!assets.logo_stacked &&
									<p>No image selected.</p>
								|| 
									<Asset id={assets.logo_stacked} />
								}
									
								<AssetPicker
									button={{
										label: assets.logo_stacked ? 'Change' : 'Choose',
										className: 'secondary medium  mt-10'
									}}
									max="1"
									types={['image']}
									onSelect={(files) => {										
										let newAssets = _.clone(assets);
										newAssets.logo_stacked = files[0];
										setAssets(newAssets);
									}}
									selected={assets.logo_stacked ? [assets.logo_stacked] : []} 
								/>
								
							</div>
							<div className="col-12 col-sm-12 col-md-4">
								<label className="label-main">Header Logo</label>
								
								{!assets.logo_horizontal &&
									<p>No image selected.</p>
								|| 
									<Asset id={assets.logo_horizontal} />
								}
									
								<AssetPicker
									button={{
										label: assets.logo_horizontal ? 'Change' : 'Choose',
										className: 'secondary medium  mt-10'
									}}
									max="1"
									types={['image']}
									onSelect={(files) => {										
										let newAssets = _.clone(assets);
										newAssets.logo_horizontal = files[0];
										setAssets(newAssets);
									}}
									selected={assets.logo_horizontal ? [assets.logo_horizontal] : []} 
								/>
							</div>
							<div className="col-12 col-sm-12 col-md-4">
								<label className="label-main">Icon</label>
								
								{!assets.icon &&
									<p>No image selected.</p>
								|| 
									<Asset id={assets.icon} />
								}
									
								<AssetPicker
									button={{
										label: assets.icon ? 'Change' : 'Choose',
										className: 'secondary medium  mt-10'
									}}
									max="1"
									types={['image']}
									onSelect={(files) => {										
										let newAssets = _.clone(assets);
										newAssets.icon = files[0];
										setAssets(newAssets);
									}}
									selected={assets.icon ? [assets.icon] : []} 
								/>
							</div>
						</div>						
					</div>
					<div className="col-12 col-sm-6 col-md-4">
						
						<legend>Colours</legend>
						
						Dropdown for header colour here ie. statusBarColor = dark-content
						
						
						
												
						
						<label className="label-main pt-20">Header</label>
						
						<Colors 
							types={['headerBackgroundColor', 'headerTextColor', 'headerIconColor']}
						/>
						
						<label className="label-main mt-20">Body</label>
						
						<Colors 
							types={['bodyBackgroundColor', 'bodyTextColor', 'bodyTitleColor']}
						/>

						<label className="label-main mt-20">Buttons</label>
						
						<Colors 
							types={['buttonBackgroundColor', 'buttonTextColor']}
						/>
						
						<label className="label-main mt-20">Input Fields</label>
						
						<Colors 
							types={['inputBackgroundColor', 'inputTextColor', 'inputPlaceholderColor', 'inputBorderColor', 'inputBorderFocusedColor', 'inputSelectedBackgroundColor', 'inputSelectedTextColor']}
						/>
						
						<label className="label-main mt-20">Scrolling Nav</label>
						
						<Colors 
							types={['scrollNavBackgroundColor', 'scrollNavTextColor', 'scrollNavSelectedColor']}
						/>
						
						<label className="label-main mt-20">Lists</label>
						
						<Colors 
							types={['listBoxBackgroundColor', 'listBoxTextColor', 'listBoxBorderColor', 'listBoxNewBackgroundColor', 'listBoxNewTextColor', 'listBoxNewBorderColor']}
						/>
					</div>
				</div>
				
				<Button
					{...props}
					label="Save"
				/>
			</form>
		</div>
	)
}


const validate = values => {
	
	const errors = {}

	if(!values.name) {
		errors.name = 'Required'
	}
	
	if(!values.title) {
		errors.title = 'Required'
	}
	
	if(!values.domain) {
		errors.domain = 'Required'
	}
	
	if(!values.code) {
		errors.code = 'Required'
	}else if(values.code.replace(/\s+/g, '').substr(0, 6).length < 6){
		errors.code = 'Invalid code'
	}
	
	return errors
}

export default reduxForm({
	form: 'brand',
	validate,
	onSubmit: Submit
})(BrandsEdit);